// export default const CartActionTypes = {
//   TOGGLE_CART_HIDDEN: "TOGGLE_CART_HIDDEN",
// };

// export CartActionTypes;

export const CartActionTypes = {
  // SET_CURRENT_USER: "SET_CURRENT_USER",
  TOGGLE_CART_HIDDEN: "TOGGLE_CART_HIDDEN",
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  CLEAR_ITEM_FROM_CART: "CLEAR_ITEM_FROM_CART",
};
